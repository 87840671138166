.create-dictionary-entry,
.dictionary-card {
    direction: ltr;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    /* margin-bottom: 20px; */
}

.create-dictionary-entry .textDiv,
.create-dictionary-entry .selectDiv,
.dictionary-card .textDiv,
.dictionary-card .selectDiv,
.create-dictionary-entry .dateDiv,
.dictionary-card .dateDiv {
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    width: auto;
}

.create-dictionary-entry .textDiv,
.dictionary-card .textDiv {
    min-width: 200px;
    max-width: 200px;
}

.create-dictionary-entry label,
.dictionary-card label {
    font-size: 16px;
    margin-bottom: 6px;
    color: #333;
    font-weight: 600;
}

.create-dictionary-entry input[type="text"],
.dictionary-card input[type="text"] {
    font-size: 15px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.2s ease;
}

.create-dictionary-entry input[type="text"]:focus,
.dictionary-card input[type="text"]:focus {
    border-color: #007bff;
    outline: none;
}

.create-dictionary-entry select,
.dictionary-card select {
    font-size: 15px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #fff;
    color: #333;
    transition: border-color 0.2s ease;
}

.create-dictionary-entry select:focus,
.dictionary-card select:focus {
    border-color: #007bff;
    outline: none;
}

.create-dictionary-entry .multi-select-container,
.dictionary-card .multi-select-container {
    display: flex;
    flex-direction: column;
}

.create-dictionary-entry .error,
.dictionary-card .error {
    color: #ff4d4f;
    font-size: 12px;
    margin-top: 4px;
}

/* create --------------------------------- */
.create-dictionary-entry {
    min-width: 681px;
}

.create-dictionary-entry button[type="submit"] {
    /* min-height: 80px; */
    padding: 10px 16px;
    font-size: 16px;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.create-dictionary-entry button[type="submit"]:hover {
    background-color: #45a049;
}

.create-dictionary-entry button[type="submit"]:disabled {
    background-color: #999;
    cursor: not-allowed;
}

.create-dictionary-entry .multi-select {
    min-width: 620px;
    max-width: 620px;
}

.create-dictionary-entry .status {
    position: absolute;
    top: 1.6%;
    right: 92%;
}

/* update --------------------------------- */
.dictionary-card button[type="submit"] {
    padding: 10px 16px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.dictionary-card button[type="submit"]:hover {
    background-color: #0056b3;
}

.dictionary-card button[type="submit"]:disabled {
    background-color: #999;
    cursor: not-allowed;
}

.dictionary-card .multi-select {
    min-width: 350px;
    max-width: 350px;
}

/* date + _id ---------------------------------------- */
.dictionary-card .dateField {
    font-size: 15px;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 6px;
    background-color: #f1f1f1;
    color: #333;
    min-width: 134px;
    min-height: 20px;
    text-align: center;
}

.dictionary-card .dateField-label {
    font-size: 16px;
    margin-bottom: 6px;
    color: #007bff;
    font-weight: 100;
}

/* ---------------------------------------- */
.dictionary-card {
    transition: all 0.3s ease-in-out;
    border: 2px solid transparent;
    background-color: #f5f5f5;
    /* צבע רקע רגיל */
}

.dictionary-card:hover {
    background-color: #e0e0e0;
    /* שינוי צבע רקע כאשר מרחפים מעל הכרטיסיה */
}

.dictionary-card.selected {
    border-color: #1976d2;
    /* מסגרת כחולה כאשר הכרטיסיה נבחרת */
    background-color: #bbdefb;
    /* שינוי צבע רקע עבור כרטיסיה נבחרת */
}