.DictionaryLogDetails {
    direction: ltr;
    width: auto;
    border-collapse: collapse;
    margin: 10px 0;
    font-size: 12px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #fafafa;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #e0e0e0;
    text-align: left;
}

.DictionaryLogDetails th {
    background-color: #3f51b5;
    color: white;
    text-align: left;
    padding: 6px 10px;
    font-weight: normal;
    border-bottom: 1px solid #e0e0e0;
    white-space: nowrap;
}

.DictionaryLogDetails td {
    padding: 6px 10px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #e0e0e0;
}

.DictionaryLogDetails tr:nth-child(even) {
    background-color: #f5f5f5;
}

.DictionaryLogDetails ul {
    padding-left: 15px;
    margin: 3px 0;
    list-style-type: disc;
}

.DictionaryLogDetails li {
    margin: 2px 0;
}

.DictionaryLogDetails th,
.DictionaryLogDetails td {
    border: none;
}

.DictionaryLogDetails tr:last-child td {
    border-bottom: none;
}