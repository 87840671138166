.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    cursor: pointer;
    opacity: 0;
    animation: fadeIn 0.3s forwards ease-out;
}

.modal {
    background: #ffffff;
    padding: 12px;
    max-width: 90%;
    margin: auto;
    border-radius: 12px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    z-index: 1001;
    cursor: default;
    opacity: 0;
    animation: zoomIn 0.3s forwards ease-out;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.modal-content {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    line-height: 1.5;
}

/* Animations */
@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

/* header + close-button */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #898989;
    border-radius: 5px;
    padding-bottom: 10px;
    color: #3c3c3c;
}

.modal-header h2 {
    margin: 0;
    flex-grow: 1;
    /* text-align: left; */
    margin-right: 20px;
    margin-left: 10px;
    font-size: 16px;
}

.close-button {
    background-color: #ff6b6b;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: white;
    padding: 4px 9px;
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.close-button:hover {
    background-color: #ff4d4d;
}

.close-button:active {
    background-color: #e63946;
    transform: translateY(0);
}

.close-button:focus {
    outline: none;
}