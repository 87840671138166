.SortFieldSelector {
    display: flex;
    flex-direction: column; /* כיוון אנכי עבור הכותרת והרשימה */
    gap: 10px;
    direction: rtl; /* מימין לשמאל */
}

.SortFieldSelector .form-controls {
    display: flex; /* רכיבים זה לצד זה */
    align-items: center;
    justify-content: flex-end; /* למקם משמאל לימין */
    gap: 16px;
}

.SortFieldSelector .MuiFormControl-root {
    flex: 1;
    direction: ltr; /* טקסט פנימי משמאל לימין */
    text-align: left; /* יישור טקסט */
}
