.container {
    /* position: relative; */
    /* height: 100%; Adjust this value according to your needs */
}

.delete-dictionary-entry-button {
    min-height: 20px;
    margin-top: auto;
    /* position: absolute;
    top: 13%;
    left: 97.8%; */
    /* right: 94.4%; */
    /* transform: translate(-50%, -50%); */
    background-color: #d9534f;
    color: white;
    border: none;
    /* padding: 3px 6px; */
    font-size: 11px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-dictionary-entry-button:hover {
    background-color: #c9302c;
}

.delete-dictionary-entry-button:active {
    background-color: #ac2925;
}

.delete-dictionary-entry-button:focus {
    outline: none;
}