.DictionaryLogItem {
    padding: 5px 8px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: row;
    margin-right: 5px;
}

.DictionaryLogItem:hover {
    background-color: #f1f1f1;
}

.DictionaryLogItem span {
    text-align: center;
    min-width: 120px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}

.DictionaryLogItem .success {
    color: green;
}

.DictionaryLogItem .error {
    color: red;
    font-weight: bold;
}

.DictionaryLogItem span.date {
    font-size: 12px;
    color: #999;
}