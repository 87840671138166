.search-form-container {
    display: flex;
    flex-direction: row;
    gap: 2px;
    padding: 2px;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-text-field {
    width: 200px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.search-text-field input {
    height: 30px;
    padding: 2px 10px;
    font-size: 18px;
}

.search-form-button {
    min-width: 70px;
    height: 32px;
}
