.dictionary-card-ai {
    transition: all 0.3s ease-in-out;
    border: 2px solid transparent;
    background-color: #f5f5f5;
    /* צבע רקע רגיל */
}

.dictionary-card-ai:hover {
    background-color: #e0e0e0;
    /* שינוי צבע רקע כאשר מרחפים מעל הכרטיסיה */
}

.dictionary-card-ai.selected-ai {
    border-color: #1976d2;
    /* מסגרת כחולה כאשר הכרטיסיה נבחרת */
    background-color: #bbdefb;
    /* שינוי צבע רקע עבור כרטיסיה נבחרת */
}