.nav-button {
    border: none;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 8px 16px;
    background-color: transparent;
    color: white !important;
    text-transform: none;
    /* לשמירה על טקסט כמו שהוא */
    font-size: 17px !important;
    transition: all 0.3s ease;
    /* להוסיף אנימציה קטנה */
}

.nav-button.active {
    border-bottom: 2px solid white;
    color: white;
    border-radius: 2px !important;
    /* צבע טקסט לכפתור פעיל */
    background-color: #004ba0;
    /* רקע לכפתור פעיל */
}

/* ------------------------------------- */
.rtl-scroll {
    text-align: right;
    direction: ltr;
    overflow-y: auto;
}

.rtl-scroll>* {
    direction: rtl;
}