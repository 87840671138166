.LoginWhiteOAuth button {
    min-width: 213px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
    color: #3c4043;
    font-family: Arial, sans-serif;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s;
}

.LoginWhiteOAuth button:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.LoginWhiteOAuth button img {
    height: 18px;
    width: 18px;
    margin-right: 8px;
}