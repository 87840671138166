/* CancelSubscription.css */

.CancelSubscription {
    direction: ltr;
    max-width: 400px;
    margin: 5px auto;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.CancelSubscription button {
    min-width: 270px;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #dc3545;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.CancelSubscription button:hover {
    background-color: #c82333;
    text-decoration: none;
}

.CancelSubscription button:disabled {
    background-color: #e0e0e0;
    color: #aaa;
    cursor: not-allowed;
}

.CancelSubscription button:focus {
    outline: none;
}

/* Loading text style */
.CancelSubscription button:after {
    content: "Loading...";
    display: inline-block;
    font-size: 14px;
    color: #fff;
    padding-left: 8px;
    visibility: hidden;
}

.CancelSubscription button[disabled]:after {
    visibility: visible;
}

.CancelSubscription button:after {
    position: absolute;
}