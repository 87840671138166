.DictionaryStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.DictionaryStatus i {
    font-size: 20px;
    border-radius: 50%;
    border: 3px solid transparent;
    /* גבול עדין ומותאם אישית */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06); */
    /* הצללה מעודכנת */
    transition: color 0.3s ease, box-shadow 0.3s ease, transform 0.2s ease;
    color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #ffffff 0%, #f4f4f4 100%);
    /* רקע עדין */
}

.DictionaryStatus i:hover {
    transform: scale(1.1);
    /* הגדלה בעת מעבר עם העכבר */
    /* box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.1); */
    /* הצללה מוגדלת */
}

.DictionaryStatus i.fa-spinner {
    background: linear-gradient(135deg, #2196F3, #2196F3);
    animation: spin 1s linear infinite;
}

.DictionaryStatus i.fa-xmark {
    background: linear-gradient(135deg, #F44336, #F44336);
}

.DictionaryStatus i.fa-check {
    background: linear-gradient(135deg, #388E3C, #388E3C);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}