/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* פס גלילה לכל האתר */
/* עבור דפדפני WebKit */
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background-color: #e0e0e0;
}

*::-webkit-scrollbar-thumb {
    background-color: #6b6b6b;
    border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #4d4d4d;
}

/* עבור פיירפוקס */
* {
    scrollbar-width: thin;
    scrollbar-color: #6b6b6b #e0e0e0;
}
