/* CreateSubscription.css */

.CreateSubscription {
    direction: ltr;
    max-width: 400px;
    min-height: 285px;
    margin: 5px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.CreateSubscription label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
}

.CreateSubscription select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.CreateSubscription .paypal-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.CreateSubscription p {
    text-align: center;
    color: #333;
    font-size: 16px;
    margin-top: 20px;
}

.CreateSubscription .success {
    color: #28a745;
}

.CreateSubscription .error {
    color: #dc3545;
}

/* SweetAlert popup customization */
.swal2-popup {
    font-size: 16px !important;
}

.swal2-icon.swal2-success {
    color: #28a745 !important;
}

.swal2-icon.swal2-error {
    color: #dc3545 !important;
}